
import { defineComponent, onBeforeMount, inject, ref } from 'vue'
import { useRouter, useRoute  } from 'vue-router';
import { useI18n } from 'vue-i18n';
import i18n from '@/i18n';

import Swal from 'sweetalert2';

export default defineComponent({
    setup() {
        const authStore: any = inject('authState');
        const showError = ref(false);
        const showSuccess = ref(false);
        const router: any     = useRouter( )

        onBeforeMount(async()=>{
            const route: any     = useRoute( );
            if (route.query.codeAction != undefined && route.query.userId != undefined) {
                const success = await authStore.confirmUserEmail(route.query.userId, route.query.codeAction);
                if (!success) {
                    showError.value = true;
                    showSuccess.value = false;
                    return;
                }
                showError.value = false;
                showSuccess.value = true;
                return;
            }
            showError.value = true;
            showSuccess.value = false;
        })
        const resendEmailConfirmation = async()=>{
            const success = await authStore.resendEmailConfirmation();
            if (!success) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.global.t('Error_Try_Again'),
                });
                return;
            } 
            Swal.fire({
                icon: 'success',
                title: i18n.global.t('Client_settings_settingscontroller_save'),
                text: i18n.global.t('Client_settings_settingscontroller_email')
            });
        }
        const goTo = (url: any) =>{
            router.push( { name: url } );
        }
        return{
            showError,
            showSuccess,
            resendEmailConfirmation,
            goTo
        }
    },
})
